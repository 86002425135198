// eslint-disable-next-line import/no-extraneous-dependencies
import React from "react";

import { Navigate, Outlet } from "react-router";
import { getToken } from "../utils/funcConst";
import { ROUTER_NAME } from "./const";

function PrivateRoute({
  children,
  component,
  ...rest
}: {
  component?: React.ComponentType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}) {
  const isLogin = getToken();
  return isLogin ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: ROUTER_NAME.U00,
        search: `next=${window.location.pathname}`,
      }}
      replace
    />
  );
}

PrivateRoute.defaultProps = {
  component: undefined,
};

export default PrivateRoute;

import { useState } from "react";
import "./App.css";
import GlobalContext from "./components/PageLayout/GlobalContex";
import AppRouter from "./router/AppRouter";

function App() {
  const [virtualKeyboardEnabled, setVirtualKeyboardEnabled] = useState(true); //VTKB: virtual keyboard

  return (
    <GlobalContext.Provider
      value={{ virtualKeyboardEnabled, setVirtualKeyboardEnabled }}
    >
      <AppRouter />
    </GlobalContext.Provider>
  );
}

export default App;

export const redirectLogin = ({ next }: { next: string }) => {
  window.location.href = "/login?next=";
};

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const convertToDecimal = (str: string) => {
  if (str.includes("/")) {
    const newstr = str.split("/");

    if (newstr.length === 2 && +newstr[1] !== 0) {
      return +newstr[0] / +newstr[1];
    } else {
      return +str;
    }
  } else {
    return +str;
  }
};
export const average = (arr: number[]) =>
  arr.reduce((p, c) => p + c, 0) / arr.length;

export const genNumber2Character = (item: number) => {
  return item < 10 ? "0" + item : item.toString();
};

export const uploadFile = (file: any) => {
  console.log(file, "file");
};

export const checkInValidNumber = ({
  max,
  min,
  number,
}: {
  max: number | undefined;
  min: number | undefined;
  number: string;
}) => {
  const lastCharacter = number.slice(-1);
  if (lastCharacter === ".") {
    return false;
  } else {
    if (number.includes(".")) {
      const arrNumber = number.split(".");
      const beforeDot = arrNumber[0];
      const afterDot = arrNumber[1];
      const arrNumberMax = !!max ? max.toString().split(".") : [];

      const beforeDotMax = arrNumberMax[0];
      const afterDotMax = arrNumberMax[1];
      const arrNumberMin = min?.toString().split(".") ?? [];

      const beforeDotMin = arrNumberMin[0];
      const afterDotMin = arrNumberMin[1];
      if (
        (max &&
          beforeDot.length <= beforeDotMax?.length &&
          afterDot.length <= afterDotMax?.length) ||
        (min &&
          beforeDot.length <= beforeDotMin?.length &&
          afterDot.length <= afterDotMin?.length)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if ((max && +number > max) || (min && +number < min)) {
        return true;
      } else {
        return false;
      }
    }
  }
};

export const sum = (num1: number, num2: number) => {
  const arr1 = num1.toString().split(".");
  const arr2 = num2.toString().split(".");
  const lenAfterDot1 = arr1?.length > 1 ? arr1[1].length : 0;
  const lenAfterDot2 = arr2?.length > 1 ? arr2[1].length : 0;

  if (lenAfterDot1 > lenAfterDot2) {
    return (num1 + num2).toFixed(lenAfterDot1);
  } else if (lenAfterDot1 < lenAfterDot2) {
    return (num1 + num2).toFixed(lenAfterDot2);
  } else {
    return (num1 + num2).toFixed(lenAfterDot1);
  }
};

export function reverseArr(arr: any[]) {
  var ret = [];
  for (var i = arr.length - 1; i >= 0; i--) {
    ret.push(arr[i]);
  }
  return ret;
}

export const cutString = (str: string) => {
  let subStrings = [];
  if (str.includes("～")) {
    subStrings = str.split("～");
    return subStrings.length <= 2
      ? subStrings.join("～\n")
      : [subStrings[0], subStrings[1]].join("～\n");
  } else {
    let numCharacterSubStr = 5;
    for (let i = 0; i < str.length; i += numCharacterSubStr) {
      subStrings.push(str.substring(i, i + numCharacterSubStr));
    }
    return subStrings.length <= 2
      ? subStrings.join("\n")
      : [subStrings[0], subStrings[1]].join("\n");
  }
};
function countJapaneseCharacters(str: string) {
  const regex = /[\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\u4E00-\u9FFF]/g;
  const japaneseCharacters = str.match(regex);

  if (japaneseCharacters) {
    return japaneseCharacters.length;
  } else {
    return 0;
  }
}

export const getFontSizeString = (str: string, isHalfSize?: boolean) => {
  const strlen = str.length;
  if (isHalfSize) {
    if (strlen >= 13) {
      return 12;
    }
    return 15;
  } else {
    if (strlen >= 12) {
      return 16;
    } else if (strlen < 7) {
      return 22;
    } else {
      return 18;
    }
  }
};
export const getFontSize = (value: any, isHalfSize?: boolean) => {
  if (typeof value === "string") {
    return getFontSizeString(value, isHalfSize);
  } else {
    return "";
  }
};
export const isNumeric = (value: string) => {
  return +value;
};

export const ROUTER_NAME = {
  HOME: "/",
  U00: "/u0",
  U01: "/u0-1",
  U02: "/u0-2",
  U1: "/u1",
  U2: "/u2",
  U3: "/u3-1",
  U3_1: "/u3-2",
  U4: "/u4",
  U5: "/u5",
  U6: "/u6",
  U7: "/u7",
  U8: "/u8",
  U9: "/u9",
  U10: "/u10",
  U10_1: "/u10-1",
  U11: "/u11",
  U12: "/u12",
  U13: "/u13",
  U14: "/u14",
  U15: "/u15",
  U16: "/u16",
  U17: "/u17",
  U18: "/u18",
  U19: "/u19",
  U20: "/u20",
  U21: "/u21",
  U22: "/u22",
  U23: "/u23",
  U24: "/u24",
  U25: "/u25",
};
